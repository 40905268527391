import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { ResponsePayloadDTO } from 'src/app/models/salesAPIResponse';
import { environment } from 'src/environments/environment';
import {
  RequestTicketClient,
  RequestTicketTile,
  Ticket,
  TicketFinancialsDto,
  UpdateTicketSite,
} from 'src/app/models/tickets/tickets';
import { ContractTicketSchedule } from 'src/app/models/deliveryContract/delivery-contract.model';
import { ClientContract } from 'src/app/models/proposals/proposal';
import { TableFilters } from 'src/app/shared/common/tableFilters';
import { ClientSite } from 'src/app/models/client/client-site';
import { ITicketActivityAddModel } from './models/ticket-activity-add.model';
import { GetTicketBulkUploadDto } from 'src/app/models/ticket-bulk-upload/ticket-bulk-upload';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  constructor(private httpClient: HttpClient) {}

  private refreshTicketBulkUploadsSource = new Subject<void>();
  refreshBulkUploads$ = this.refreshTicketBulkUploadsSource.asObservable();
  triggerrefreshTicketBulkUploadsTable() {
    this.refreshTicketBulkUploadsSource.next();
  }

  emitCurrentStatus = new BehaviorSubject<string>('');
  getCurrentTicketStatus$ = this.emitCurrentStatus.asObservable();
  emitActivityChange = new BehaviorSubject<boolean>(false);
  getActivityChange$ = this.emitActivityChange.asObservable();
  emitSelectedClientSite = new BehaviorSubject<ClientSite>({
    id: 0,
    name: '',
    city: '',
    state: '',
    countryId: 0,
    siteNumber: '',
    countryName: '',
    address: '',
    postalCode: '',
    phone: '',
    timeZone: '',
    clientId: 0,
    hqAddress: false,
    longitude: '',
    latitude: '',
  });
  getSelectedClientSite$ = this.emitSelectedClientSite.asObservable();

  getTickets = new Subject<number>();
  ticketComponent_getTickets$ = this.getTickets.asObservable();

  ticketComponent_getTickets(param: number): void {
    this.getTickets.next(param);
  }

  getTicketsByContractId(
    payload: TableFilters,
    contractId: number
  ): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.workOrderApiBaseUrl}/contract/${contractId}/tickets?sort=${payload.sort}&search=${payload.search}&page=${payload.page}&pageSize=${payload.pageSize}&isAscending=${payload.isAscending}`
    );
  }

  getTicketById(id: number): Observable<ResponsePayloadDTO<Ticket>> {
    return this.httpClient.get<ResponsePayloadDTO<Ticket>>(
      `${environment.workOrderApiBaseUrl}/contract/` + id
    );
  }

  getTicketSite(ticketId: number): Observable<ClientSite | null> {
    return this.httpClient
      .get<any>(`${environment.workOrderApiBaseUrl}/tickets/${ticketId}/site`)
      .pipe(
        map<any, ClientSite | null>((source) => {
          const data = source.results;

          return data;
        })
      );
  }

  AddTicketActivities(
    ticketId: number,
    activities: ITicketActivityAddModel[]
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.workOrderApiBaseUrl}/Tickets/${ticketId}/activities`,
      activities
    );
  }

  getTicketActivitiesByTicketId(
    payload: TableFilters,
    ticketId: number
  ): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.workOrderApiBaseUrl}/Tickets/${ticketId}/activities?sort=${payload.sort}&search=${payload.search}&page=${payload.page}&pageSize=${payload.pageSize}&isAscending=${payload.isAscending}`
    );
  }

  createTicketScheduleList(
    ticketSchedules: ContractTicketSchedule[],
    ticketId: number
  ): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${environment.workOrderApiBaseUrl}/tickets/${ticketId}/schedules-list`,
      ticketSchedules
    );
  }

  CreateTicketSchedual(
    ticketSchedual: ContractTicketSchedule,
    ticketId: number
  ): Observable<any> {
    const localUserId = localStorage.getItem('b2CUserId');
    ticketSchedual.createdBy = JSON.parse(localUserId || '');

    return this.httpClient.post<ContractTicketSchedule>(
      `${environment.workOrderApiBaseUrl}/tickets/${ticketId}/schedules`,
      ticketSchedual
    );
  }

  GetTicketSchedule(
    ticketId: number,
    payload?: TableFilters
  ): Observable<ResponsePayloadDTO<ContractTicketSchedule[]>> {
    if (payload) {
      return this.httpClient.get<ResponsePayloadDTO<ContractTicketSchedule[]>>(
        `${environment.workOrderApiBaseUrl}/tickets/${ticketId}/schedules?sort=${payload?.sort}&search=${payload?.search}&page=${payload?.page}&pageSize=${payload?.pageSize}&isAscending=${payload?.isAscending}`
      );
    }
    return this.httpClient.get<ResponsePayloadDTO<ContractTicketSchedule[]>>(
      `${environment.workOrderApiBaseUrl}/tickets/${ticketId}/schedules`
    );
  }

	deleteTicketScheduleById(ticketId: number) : Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.workOrderApiBaseUrl}/tickets/${ticketId}/schedules`
		);
	}

	deleteTicketActivity(
		ticketActivityId: number,
		ticketId: number
	): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.workOrderApiBaseUrl}/tickets/${ticketId}/activity-delete/${ticketActivityId}`
		);
	}

  updateTicketSchedual(
    ticketSchedual: ContractTicketSchedule,
    ticketId: number
  ): Observable<ResponsePayloadDTO<boolean>> {
    return this.httpClient.put<ResponsePayloadDTO<boolean>>(
      `${environment.workOrderApiBaseUrl}/tickets/${ticketId}/schedules`,
      ticketSchedual
    );
  }

  getTicketsByClientId(
    clientID: number,
    payload?: any
  ): Observable<ResponsePayloadDTO<ClientContract[]>> {
    if (payload === null || payload == undefined) {
      let clientContractRequest = {
        page: 0,
        pageSize: 0,
        contractId: 0,
        isAscending: false,
        search: '',
        sort: '',
      };
      payload = clientContractRequest;
    }
    return this.httpClient.get<ResponsePayloadDTO<ClientContract[]>>(
      `${environment.workOrderApiBaseUrl}/tickets/client-tickets/${clientID}?Page=${payload?.page}&PageSize=${payload?.pageSize}&ContractId=${payload?.contractId}&Sort=${payload?.sort}&IsAscending=${payload?.isAscending}&Search=${payload?.search}`
    );
  }

  updateTicketSite(
    updateTicketSite: UpdateTicketSite
  ): Observable<ResponsePayloadDTO<boolean>> {
    return this.httpClient.put<ResponsePayloadDTO<boolean>>(
      `${environment.workOrderApiBaseUrl}/Tickets/ticket-site`,
      updateTicketSite
    );
  }

  getTicketFinancialsById(
    ticketId: number
  ): Observable<ResponsePayloadDTO<TicketFinancialsDto>> {
    return this.httpClient.get<ResponsePayloadDTO<TicketFinancialsDto>>(
      `${environment.workOrderApiBaseUrl}/tickets/${ticketId}/financials`
    );
  }

  editTicketTitle(
    ticketDetail: RequestTicketTile
  ): Observable<ResponsePayloadDTO<boolean>> {
    return this.httpClient.patch<ResponsePayloadDTO<boolean>>(
      `${environment.workOrderApiBaseUrl}/tickets/title`,
      ticketDetail
    );
  }

  editTicketClient(
    ticketDetail: RequestTicketClient
  ): Observable<ResponsePayloadDTO<boolean>> {
    return this.httpClient.patch<ResponsePayloadDTO<boolean>>(
      `${environment.workOrderApiBaseUrl}/tickets/endclient`,
      ticketDetail
    );
  }

  updateTicketActivity(payload): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.workOrderApiBaseUrl}/tickets/details`,
      payload
    );
  }
  updateTicketActivityMultiple(poId, payload): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.workOrderApiBaseUrl}/tickets/${poId}/activities`,
      payload
    );
  }

  getTicketSummary(ticketId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.workOrderApiBaseUrl}/tickets/${ticketId}/summary`
    );
  }

  createTicketSummary(ticketId: number, payload: any): Observable<any> {
    return this.httpClient.post(
      `${environment.workOrderApiBaseUrl}/tickets/${ticketId}/summary`,
      payload
    );
  }

  updateTicketSummary(ticketId: number, payload: any): Observable<any> {
    return this.httpClient.put(
      `${environment.workOrderApiBaseUrl}/tickets/${ticketId}/summary`,
      payload
    );
  }

  getTicketOverview(ticketId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.workOrderApiBaseUrl}/tickets/${ticketId}/ticket-overview`
    );
  }

  bcSyncManual(ticketId: number): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.workOrderApiBaseUrl}/tickets/${ticketId}/bc-sync-manual`
    );
  }

  getBulkTicketUploadsByClientIdAndContractIdddd(
    clientId: number,
    contractId: number,
    tableFilter: TableFilters,
    additionalParams: any = {}
  ): Observable<ResponsePayloadDTO<any>> {
    const queryParams = {
      Page: tableFilter?.page,
      PageSize: tableFilter?.pageSize,
      Search: tableFilter?.search,
      Sort: tableFilter?.sort,
      IsAscending: tableFilter?.isAscending,
      ...additionalParams,
    };

    return this.httpClient.get<ResponsePayloadDTO<any>>(
      `${environment.workOrderApiBaseUrl}/tickets/bulk-uploads/${clientId}/${contractId}`,
      {
        params: queryParams,
      }
    );
  }

  getBulkTicketUploadsByClientIdAndContractId(
    clientId: number,
    contractId: number,
    tableFilter: TableFilters,
    additionalParams: any = {}
  ): Observable<ResponsePayloadDTO<GetTicketBulkUploadDto[]>> {
    const queryParams = {
      Page: tableFilter?.page,
      PageSize: tableFilter?.pageSize,
      Search: tableFilter?.search,
      Sort: tableFilter?.sort,
      IsAscending: tableFilter?.isAscending,
      ...additionalParams,
    };

    return this.httpClient.get<ResponsePayloadDTO<GetTicketBulkUploadDto[]>>(
      `${environment.workOrderApiBaseUrl}/tickets/bulk-uploads/${clientId}/${contractId}`,
      {
        params: queryParams,
      }
    );
  }

  downloadFailedUploads(ticketBulkUploadId: number): Observable<Blob> {
    return this.httpClient.get(
      `${environment.workOrderApiBaseUrl}/tickets/DownloadFailedUploads/${ticketBulkUploadId}`,
      { responseType: 'blob' }
    );
  }
}
