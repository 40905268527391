import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldFlowIconButtonComponent} from "./field-flow-icon-button.component";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [
    FieldFlowIconButtonComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    FieldFlowIconButtonComponent
  ]
})
export class FieldFlowIconButtonModule {
}
