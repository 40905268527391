import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CarbonModule} from '../carbon.module';
import {InputDialogComponent} from './components/input-dialog/input-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SkeletonTableComponent} from './skeleton-table/skeleton-table.component';
import {
  AddActivityResourceComponent
} from './components/project-activity-resources/add-activity-resource/add-activity-resource.component';
import {NumberModule, TagModule} from 'carbon-components-angular';
import {LoaderComponent} from './components/loader/loader.component';
import {CompetencyDialogComponent} from './components/competency-dialog/competency-dialog.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {
  AgreementDeliveryMediumConfirmationDialogueComponent
} from './components/agreement-delivery-medium-confirmation/agreement-delivery-medium-confirmation-dialogue/agreement-delivery-medium-confirmation-dialogue.component';
import {
  ClientAlignmentDialogueComponent
} from './components/client-alignment-dialogue/client-alignment-dialogue/client-alignment-dialogue.component';
import {
  TurnoverProcessDialogueComponent
} from './components/turnover-process-dialogue/turnover-process-dialogue/turnover-process-dialogue.component';
import {TagInputModule} from 'ngx-chips';
import {
  PoPostingPlatformSelectionDialogueComponent
} from './components/po-posting-platform-selection-dialogue/po-posting-platform-selection-dialogue.component';
import {PoScopeComponent} from '../views/ticket/po-modal/components/po-scope/po-scope.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {
  PoDeliveryMediumCofirmationDialogComponent
} from './components/po-delivery-medium-cofirmation-dialog/po-delivery-medium-cofirmation-dialog.component';
import {PermissionTableComponent} from './components/permission-table/permission-table.component';
import {EditModule, UserModule, WarningModule} from '@carbon/icons-angular';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {ActivitiesResourceComponent} from './components/activities-resource/activities-resource.component';
import {
  TicketScheduleInputComponent
} from '../views/ticket/tabs/ticket-schedule/ticket-schedule-input/ticket-schedule-input.component';
import {
  UploadAgreementDetailsComponent
} from './components/agreement-delivery-medium-confirmation/agreement-delivery-medium-confirmation-dialogue/components/upload-agreement-details/upload-agreement-details.component';
import {ActivitiesModalComponent} from './components/activities-modal/activities-modal.component';
import {PaginationBuilderModule} from './components/pagination-builder/pagination-builder.module';
import {FeatureFlagModule} from "./directives/feature-flag/feature-flag.module";
import {MatIconModule} from '@angular/material/icon';
import {LoadingHandlerModule} from './components/loading-handler/loading-handler.module';
import {QuillModule} from 'ngx-quill';
import {
  ContractTicketActivitiesComponent
} from '../views/contract/subroutes/contract-tickets/add-ticket/ticket-activities/ticket-activities.component';
import {ConfirmationDialogModule} from "./components/confirmation-dialog/confirmation-dialog.module";
import { FieldFlowBannerModule } from "../../../projects/fieldflow-ui/src/lib/components/field-flow-banner/field-flow-banner.module";
import { TechnicianSchedulingConflictModule } from './components/technician-scheduling-conflict/technician-scheduling-conflict.module';
import { FieldFlowSectionCardModule } from 'projects/fieldflow-ui/src/lib/components/field-flow-section-card/field-flow-section-card.module';
import { FieldFlowDialogModule } from 'projects/fieldflow-ui/src/lib/components/fieldflow-dialog/fieldflow-dialog.module';
import { FieldFlowButtonModule } from 'projects/fieldflow-ui/src/lib/components/field-flow-button/field-flow-button.module';
import { TicketAddEditScheduleComponent } from '../views/ticket/tabs/ticket-schedule/ticket-add-edit-schedule/ticket-add-edit-schedule.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FieldFlowHeaderModule } from "../../../projects/fieldflow-ui/src/lib/components/field-flow-header/field-flow-header.module";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FieldFlowIconButtonModule } from 'projects/fieldflow-ui/src/lib/components/field-flow-icon-button/field-flow-icon-button.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    InputDialogComponent,
    SkeletonTableComponent,
    AddActivityResourceComponent,
    PoScopeComponent,
    CompetencyDialogComponent,
    LoaderComponent,
    AgreementDeliveryMediumConfirmationDialogueComponent,
    ClientAlignmentDialogueComponent,
    TurnoverProcessDialogueComponent,
    PoPostingPlatformSelectionDialogueComponent,
    PoDeliveryMediumCofirmationDialogComponent,
    PermissionTableComponent,
    ActivitiesResourceComponent,
    TicketScheduleInputComponent,
    UploadAgreementDetailsComponent,
    ActivitiesModalComponent,
    ContractTicketActivitiesComponent,
    TicketAddEditScheduleComponent
  ],

  imports: [
    CommonModule,
    TagInputModule,
    CarbonModule,
    FormsModule,
    ReactiveFormsModule,
    NumberModule,
    NgSelectModule,
    TagModule,
    EditorModule,
    EditModule,
    UserModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    WarningModule,
    PaginationBuilderModule,
    FeatureFlagModule,
    MatIconModule,
    LoadingHandlerModule,
    QuillModule,
    ConfirmationDialogModule,
    FieldFlowBannerModule,
    TechnicianSchedulingConflictModule,
    FieldFlowSectionCardModule,
    FieldFlowDialogModule,
    FieldFlowButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FieldFlowHeaderModule,
    MatCheckboxModule,
    ScrollingModule,
    FieldFlowIconButtonModule,
    MatTooltipModule
],
  exports: [SkeletonTableComponent, LoaderComponent, PoScopeComponent, PermissionTableComponent, ActivitiesResourceComponent, TicketScheduleInputComponent, ContractTicketActivitiesComponent]
})
export class SharedModule {
}
