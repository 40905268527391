<lib-navbar-dropdown [state]="state" title="User Options">

  <button (click)="launchCopilot()" class="action">

    <mat-icon fontSet="material-symbols-outlined">Signature</mat-icon>
    <span>Edit Email Signature</span>

  </button>

  <button (click)="logOut.emit()" class="action danger">

    <mat-icon fontSet="material-symbols-outlined">logout</mat-icon>
    <span>Logout</span>

  </button>

</lib-navbar-dropdown>
